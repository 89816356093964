const Spaces = ({
  query
}: {
  query: URLSearchParams;
}) => {
  const spacesOptions = [{
    id: "yany",
    label: "nenhum"
  }, {
    id: "yoneplus",
    label: "1+"
  }, {
    id: "ytwoplus",
    label: "2+"
  }, {
    id: "ythreeplus",
    label: "3+"
  }, {
    id: "yfourplus",
    label: "4+"
  }, {
    id: "yfiveplus",
    label: "5+"
  }];
  return <>
      {spacesOptions.map((option, index) => <div className="selection" key={option.id}>
          <input id={option.id} name="ybath" type="radio" onChange={e => {
        query.set("parking_spaces", option.label.replace("+", ""));
      }} defaultChecked // Set the first option as defaultChecked
      />
          <label htmlFor={option.id}>{option.label}</label>
        </div>)}
    </>;
};
export default Spaces;