"use client";

import React, { useState } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
const maxValue = 70000000;
const PriceRange = ({
  query
}: {
  query: URLSearchParams;
}) => {
  const [price, setPrice] = useState({
    value: {
      min: 0,
      max: 70000000
    }
  });

  // Faixa de preço handler
  const handleOnChange = value => {
    setPrice({
      value
    });
    const realMax = value.max !== 70000000 ? value.max : undefined;
    query.set("min_price", value.min);
    query.set("max_price", realMax);
  };
  return <>
      <div className="range-wrapper">
        <InputRange formatLabel={() => ``} maxValue={70000000} minValue={0} value={price.value} step={1000000} onChange={value => handleOnChange(value)}
      //@ts-ignore
      id="slider" data-sentry-element="InputRange" data-sentry-source-file="PriceRange.tsx" />
        <div className="d-flex align-items-center">
          {/* <span id="slider-range-value1">
            {Number(price.value.min).toLocaleString("pt-br", {
              style: "currency",
              currency: "brl",
            })}
           </span>
           <i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon" />
           <span id="slider-range-value2">
            {price.value.max < 50000000
              ? Number(price.value.max).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "brl",
                })
              : "R$ 50.000.000,00+"}
           </span> */}

          <input id="slider-range-value1" value={price.value.min.toLocaleString()} onChange={e => {
          const min = Number(e.target.value);
          const max = price.value.max;
          handleOnChange({
            min,
            max
          });
        }} />
          <i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon" />
          <input id="slider-range-value2" value={price.value.max < maxValue ? price.value.max.toLocaleString() : `${maxValue.toLocaleString()}+`} onChange={e => {
          const v = e.target.value.replaceAll(",", "").replaceAll(".", "");
          const value = Number(v);
          const min = price.value.min;
          const max = value <= maxValue ? value : maxValue;
          handleOnChange({
            min,
            max
          });
        }} />
        </div>
      </div>
    </>;
};
export default PriceRange;