"use client";

import React from "react";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import { cn } from "@/lib/utils";
const ApartmentTypes = ({
  apartments
}: {
  apartments: {
    neighborhood: string;
    region: string;
    count: number;
  }[];
}) => {
  const t = useTranslations("home.sections.section2.propertie");
  const router = useRouter();
  if (!apartments) {
    return null;
  }
  const apartmentData = [{
    ...apartments[0],
    className: "col-lg-6",
    image: "https://res.cloudinary.com/mbras/image/upload/q_auto,f_auto/v1709442035/site/boa-vista-regioes_omqebz.jpg"
  }, {
    ...apartments[1],
    className: "col-6 col-lg-3",
    image: "/images/home/jardim-paulistano.jpg"
  }, {
    ...apartments[2],
    className: "col-6 col-lg-3",
    image: "https://res.cloudinary.com/mbras/image/upload/q_auto,f_auto/v1709442952/site/vila-nova-regioes_d747xt.jpg"
  }, {
    ...apartments[3],
    className: "col-6 col-lg-3",
    image: "https://res.cloudinary.com/mbras/image/upload/q_auto,f_auto/v1709444251/site/itaim-explore_ua4ezj.jpg"
  }, {
    ...apartments[4],
    className: "col-6 col-lg-3",
    image: "/images/home/jardim-europa.jpg"
  }, {
    ...apartments[5],
    className: "col-lg-6",
    image: "https://res.cloudinary.com/mbras/image/upload/q_auto,f_auto/v1709443833/site/cj-explore_dazggi.jpg"
  }];
  return <>
      {apartmentData?.map((apartment, index) => <div key={index} className={cn(apartment.className, "cursor-pointer")} onClick={() => {
      router.push(`/fast?type=sale&locations=${apartment.neighborhood || apartment.region}`);
    }}>
          <div className="feature-style1 mb30">
            <div className="feature-img">
              <Image width={591} height={270} className="w-full h-[270px] object-cover"
          // src={
          //   apartment.photos &&
          //   apartment.photos[0] &&
          //   apartment.photos[0].src
          //     ? apartment.photos[0].src?.imagekit
          //     : "https://google.com"
          // }
          // onError={(e) => {
          //   apartment.photos &&
          //     apartment.photos[0] &&
          //     apartment.photos[0].src &&
          //     (e.currentTarget.src = apartment.photos[0].src?.imagekit);
          // }}
          src={apartment.image} alt="Imóveis por região" />
            </div>
            <div className="feature-content">
              <div className="top-area">
                <p className={`title mb-1 font-semibold text-lg rounded-xl`} style={{
              textShadow: "0 0 4px rgba(0, 0, 0, 0.5)"
            }}>
                  {apartment.neighborhood}
                </p>
                {/*} <p className="text">
                  {apartment.count} {t("properties")}
                 </p>*/}
              </div>
              <div className="bottom-area ud-btn2">
                {t("buttons.seeAllCities")}
                <i className="fal fa-arrow-right-long" />
              </div>
            </div>
          </div>
        </div>)}
    </>;
};
export default ApartmentTypes;