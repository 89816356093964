import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { AnalyticsBrowser } from "@segment/analytics-next";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || "",
});
