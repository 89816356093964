"use client";

import styled from "styled-components";

export const Header = styled.header`
  &.nav-homepage-style {
    background-color: transparent;
    z-index: 9 !important;
    border: none;
    nav {
      ul {
        li {
          a {
            color: white;
            font-weight: 400;
            font-size: 16px;
          }
          ul {
            &.sub-menu {
              -o-box-shadow: 2px 0px 20px rgba(24, 26, 32, 0.1);
              box-shadow: 2px 0px 20px rgba(24, 26, 32, 0.1);
            }
          }
        }
      }
    }
    .login-info {
      p {
        font-weight: 400 !important;
        font-size: 16px;
      }
    }
  }
`;

export const Hero = styled.div`
  .custom_thumbs {
    height: fit-content;
    top: 80%;
    .swiper {
      height: auto !important;
    }
  }
`;

export const About = styled.div`
  h5 {
    font-size: 2rem;
  }
  p {
    font-size: 17px;
  }
`;
