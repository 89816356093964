"use client";

import { useState, createContext, useContext, useEffect } from "react";
import { useSession } from "next-auth/react";
import api from "../services/api.service";
import { toast } from "sonner";
import { LikeType } from "@mbras-emprendimentos/types";

// interface Values {
//   likes: LikeType[];
//   getData(): any;
// }

const GlobalContext = createContext({
  likes: [],
  getData: () => {}
});
export default function GlobalContextProvider({
  children
}) {
  const {
    data: session
  } = useSession();
  const [likes, setLikes] = useState([]);
  async function getLikes() {
    if (session?.user?.access_token) {
      try {
        const response = await (await api("/customer/like", {
          headers: {
            Authorization: session?.user?.access_token || ""
          }
        })).json();
        console.log(response);
        if (response.likes) {
          setLikes(response.likes);
        }
      } catch (error) {
        console.error(error);
        toast.error("Erro", {
          description: "Falha ao recuperar lista de imóveis favoritos"
        });
      }
    }
  }
  async function getData() {
    await getLikes();
  }
  useEffect(() => {
    getData();
  }, [session]);
  return <GlobalContext.Provider value={{
    likes,
    getData
  }} data-sentry-element="unknown" data-sentry-component="GlobalContextProvider" data-sentry-source-file="global.context.jsx">
      {children}
    </GlobalContext.Provider>;
}
export function useGlobalContext() {
  return useContext(GlobalContext);
}