"use client";
import styled from "styled-components";

export const Card = styled.div`
    transition: all .5s;
    .list-thumb{
        img{
            border-radius: 10px;
        }
    }
    .list-title{
        font-weight: 700;
        font-size: 16px;
    }
    .list-meta2{
        .list-price{
            padding: 0;
            color: #7BBFAB;
            font-weight: 700;
            font-size: 17px;
        }
    }
    &:hover{
        -webkit-box-shadow: 2px 2px 27px -1px rgba(0,0,0,0.23);
-moz-box-shadow: 2px 2px 27px -1px rgba(0,0,0,0.23);
box-shadow: 2px 2px 27px -1px rgba(0,0,0,0.23);
        img{
            transform: scale(1) rotate(0) !important;
        }
        .list-title{
            text-decoration: none;
        }
    }
`;
