"use client";

import Image from "next/image";
import Link from "next/link";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useTranslations } from "next-intl";
import { ImmobileType } from "@mbras-emprendimentos/types";
import ImmobileCard from "../ImmobileCard";
const FeaturedListings = ({
  featuredImmobiles = []
}) => {
  const t = useTranslations("home.sections.section1.propertie");
  return <>
      <Swiper spaceBetween={30} modules={[Navigation, Pagination]} navigation={{
      nextEl: ".featured-next__active",
      prevEl: ".featured-prev__active"
    }} pagination={{
      el: ".featured-pagination__active",
      clickable: true
    }} slidesPerView={1} breakpoints={{
      300: {
        slidesPerView: 1.2
      },
      768: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 3
      }
    }} data-sentry-element="Swiper" data-sentry-source-file="FeaturedListings.tsx">
        {featuredImmobiles?.map((immobile: ImmobileType) => <SwiperSlide key={immobile.ref}>
            <ImmobileCard key={immobile.ref} l={immobile} />
          </SwiperSlide>)}
      </Swiper>

      <div className="row align-items-center justify-content-center">
        <div className="col-auto">
          <button className="featured-prev__active swiper_button" aria-label="Anterior">
            <i className="far fa-arrow-left-long" />
          </button>
        </div>
        {/* End prev */}

        <div className="col-auto">
          <div className="pagination swiper--pagination featured-pagination__active" />
        </div>
        {/* End pagination */}

        <div className="col-auto">
          <button className="featured-next__active swiper_button" aria-label="Próximo">
            <i className="far fa-arrow-right-long" />
          </button>
        </div>
        {/* End Next */}
      </div>
      {/* End .col for navigation and pagination */}
    </>;
};
export default FeaturedListings;