"use client";

import { useTranslations } from "next-intl";
import dynamic from "next/dynamic";
const Select = dynamic(() => import("react-select"), {
  ssr: false
});
interface Props {
  neighborhoods: string[];
  query: URLSearchParams;
  setQuery: React.Dispatch<React.SetStateAction<URLSearchParams>>;
}
const Location = (props: Props) => {
  const inqueryType = [{
    value: "all",
    label: "Todos"
  }, {
    value: "Jardim Europa",
    label: "Jardim Europa"
  }, {
    value: "Itaim Bibi",
    label: "Itaim Bibi"
  }, {
    value: "Vila Nova Conceição",
    label: "Vila Nova Conceição"
  }, {
    value: "Cidade Jardim",
    label: "Cidade Jardim"
  }, {
    value: "Jardim América",
    label: "Jardim América"
  }, {
    value: "Jardim Paulista",
    label: "Jardim Paulista"
  }, {
    value: "Jardim Paulistano",
    label: "Jardim Paulistano"
  }, {
    value: "Alto de Pinheiros",
    label: "Alto de Pinheiros"
  }, {
    value: "Campo Belo",
    label: "Campo Belo"
  }, {
    value: "Brooklin",
    label: "Brooklin"
  }, ...(props.neighborhoods?.sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  }).map(n => ({
    value: n,
    label: n
  })) || [])];
  const customStyles = {
    option: (styles, {
      isFocused,
      isSelected,
      isHovered
    }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "var(--primary)" : isHovered ? "var(--primary)12" : isFocused ? "var(--primary)12" : undefined
      };
    }
  };
  const t = useTranslations("home.filterWithProperty.filterContent");
  return <>
      <label className="fz14" htmlFor="location-select">
        {t("fields.location.label")}
      </label>
      <Select id="location-select" name="location-select" defaultValue={(props?.query?.get("locations") || "").split(",").map(i => ({
      value: i,
      label: i
    })).filter(a => a.value !== "" && a.label !== "").length > 0 ? (props?.query?.get("locations") || "").split(",").map(i => ({
      value: i,
      label: i
    })).filter(a => a.value !== "" && a.label !== "") : [inqueryType[0]]} options={inqueryType}
    //@ts-ignore
    styles={customStyles} className="text-start select-borderless" classNamePrefix="select" onChange={(e: any) => {
      props.query.set("locations", e!.value);
      props.setQuery(new URLSearchParams({
        ...Object.fromEntries(props.query),
        locations: e!.value
      }));
    }} required isClearable={false} data-sentry-element="Select" data-sentry-source-file="Location.tsx" />
    </>;
};
export default Location;