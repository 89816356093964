const colors = {
  primary: "#21293A",
  primary_color: "#fff",
  secondary: "#4D7079",
  secondary_color: "#fff",
  third: "#A57F59",
  third_color: "",
};

export default colors;
