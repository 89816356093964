"use client";

import React, { useEffect } from "react";
import { useTranslations } from "next-intl";

const PropertyType = ({ query, setQuery }) => {
  const t = useTranslations("home.filterWithProperty.advanceFilterModal");

  const options = [
    // { value: undefined, label: t("fields.type.items.all") },
    { value: "apartments", label: t("fields.type.items.apartments") },
    { value: "houses", label: t("fields.type.items.houses") },
    {
      value: "condominium_houses",
      label: t("fields.type.items.condominium_houses"),
    },

    { value: "roof", label: t("fields.type.items.roof") },
    { value: "commercial", label: t("fields.type.items.commercial") },
    { value: "ground", label: t("fields.type.items.ground") },
  ];

  const [selecteds, setSelecteds] = React.useState(
    (query?.get("property_type") || "")
      ?.split(",")
      .map((i) => ({ value: i, label: t(`fields.type.items.${i}`) }))
  );

  useEffect(() => {
    setSelecteds((query?.get("property_type") || "")?.split(",") || []);
  }, [query]);

  return (
    <>
      {options.map((option, index) => (
        <label className="custom_checkbox" key={index}>
          {option.label}
          <input
            type="checkbox"
            checked={selecteds?.includes(option.value)}
            onChange={(e) => {
              setQuery((query) => {
                if (e.target.checked) {
                  const newArray = [
                    ...(query?.get("property_type") || "")?.split(","),
                    option.value,
                  ];
                  setSelecteds(newArray);
                  return new URLSearchParams({
                    ...(Object.fromEntries(query) || {}),
                    property_type: newArray,
                  });
                }

                const newArray = (query?.get("property_type") || "")?.split(
                  ","
                );

                newArray?.splice(newArray?.indexOf(option.value));

                setSelecteds(newArray);

                return new URLSearchParams({
                  ...(Object.fromEntries(query) || {}),
                  property_type: newArray,
                });
              });
            }}
          />
          <span className="checkmark" />
        </label>
      ))}
    </>
  );
};

export default PropertyType;
