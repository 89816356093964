const Suites = ({
  query
}: {
  query: URLSearchParams;
}) => {
  const bedOptions = [{
    id: "xany",
    label: "nenhum"
  }, {
    id: "xoneplus",
    label: "1+"
  }, {
    id: "xtwoplus",
    label: "2+"
  }, {
    id: "xthreeplus",
    label: "3+"
  }, {
    id: "xfourplus",
    label: "4+"
  }, {
    id: "xfiveplus",
    label: "5+"
  }];
  return <>
      {bedOptions.map((option, index) => <div className="selection" key={option.id}>
          <input id={option.id} name="xbeds" type="radio" onChange={e => {
        query.set("suites", option.label.replace("+", ""));
      }} defaultChecked // Set the first option as defaultChecked
      />
          <label htmlFor={option.id}>{option.label}</label>
        </div>)}
    </>;
};
export default Suites;