"use client";

import React, { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import LookingFor from "./LookingFor";
import Location from "./Location";
import PropertyType from "../../listing/sidebar/PropertyType";
import { useTranslations } from "next-intl";
import { DropdownMenu } from "@radix-ui/themes";
import colors from "@/colors";
interface Props {
  neighborhoods: string[];
}
const maxValue = 70000000;
const FilterContent = (props: Props) => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState("sale");
  const t = useTranslations("home.filterWithProperty.filterContent");
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    query.set("type", tab);
  };
  const tabs = [{
    id: "sale",
    label: t("buttons.sale")
  }, {
    id: "rent",
    label: t("buttons.rent")
  }];
  const [query, setQuery] = useState<URLSearchParams>(typeof window !== "undefined" ? new URLSearchParams(window.location.search || {
    type: activeTab
  }) : new URLSearchParams({
    type: activeTab
  }));
  const [keyword, setKeyword] = useState<string>(query.get("keyword") || "");
  const [price, setPrice] = useState({
    value: {
      min: Number(query.get("min_price")) || 0,
      max: Number(query.get("max_price")) || maxValue
    }
  });

  // Faixa de preço handler
  const handleOnChange = (value: any) => {
    setPrice({
      value
    });
    query.set("min_price", value.min.toString());
    if (value.max !== maxValue) {
      query.set("max_price", value.max.toString());
    } else {
      query.delete("max_price");
    }
  };
  useEffect(() => {
    const newUrl = `${window.location.pathname}?${query.toString()}`;
    window.history.replaceState(null, "", newUrl);
  }, [query]);
  return <div className="advance-style4 at-home5 mt-100 mt50-lg mb10 mx-auto animate-up-2 relative" data-sentry-component="FilterContent" data-sentry-source-file="FilterContent.tsx">
      <ul className="nav nav-tabs p-0 m-0">
        {tabs.map(tab => <li className="nav-item" key={tab.id}>
            <button className={`nav-link ${activeTab === tab.id ? "active" : ""}`} onClick={() => handleTabClick(tab.id)}>
              {tab.label}
            </button>
          </li>)}
      </ul>

      <div className="tab-content text-start">
        {tabs.map(tab => <div className={`${activeTab === tab.id ? "active" : ""} tab-pane`} key={tab.id}>
            <div className="advance-content-style3 at-home5">
              <div className="row align-items-center">
                <div className="col-md-4 col-xl-3 bdrr1 bdrrn-sm flex-1">
                  <label>{t("fields.search.label")}</label>
                  <div className="advance-search-field position-relative">
                    <form onSubmit={e => {
                  e.preventDefault();
                  let path = `/fast?${query.toString()}`;
                  router.push(path);
                }} className="form-search position-relative">
                      <div className="box-search">
                        <input className="form-control bgc-f7 bdrs12 ps-0" type="text" value={keyword} onChange={e => {
                      setKeyword(e.target.value);
                      // query.set("keyword", );
                      setQuery(new URLSearchParams({
                        ...Object.fromEntries(query),
                        keyword: e.target.value
                      }));
                    }} placeholder={`${t("fields.search.placeholder")} ${tab.label}`} />
                      </div>
                    </form>
                  </div>
                </div>
                {/* End .col-3 */}

                <div className="col-md-4 col-xl-2 bdrr1 bdrrn-sm px20 pl15-sm">
                  <div className="mt-3 mt-md-0 px-0">
                    <div className="bootselect-multiselect">
                      <label className="fz14">
                        {t("fields.lookingFor.label")}
                      </label>
                      {/* <LookingFor query={query} /> */}
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }} aria-label="Selecione uma opção" role="button">
                            <span>Selecione</span>
                            <DropdownMenu.TriggerIcon aria-hidden="true" />
                          </div>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content className="p-3" style={{
                      zIndex: 9999
                    }}>
                          <PropertyType query={query} setQuery={setQuery} />
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </div>
                  </div>
                </div>
                {/* End col-md-4 */}

                <div className="col-md-4 col-xl-2 bdrr1 bdrrn-sm px20 pl15-sm">
                  <div className="mt-3 mt-md-0">
                    <div className="bootselect-multiselect">
                      <Location neighborhoods={props.neighborhoods} query={query} setQuery={setQuery} />
                    </div>
                  </div>
                </div>
                {/* End col-md-4 */}

                <div className="col-md-4 col-xl-2 bdrr1 bdrrn-sm px20 pl15-sm" style={{
              width: 235
            }}>
                  <div className="mt-3 mt-md-0">
                    <div className="dropdown-lists">
                      <label className="fz14 mb-1400">
                        {t("fields.price.label")}
                      </label>
                      <div className="btn open-btn text-start dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" style={{
                    fontSize: "13px"
                  }}>
                        {Number(price.value.min).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "brl"
                    }).slice(0, -3)}{" "}
                        -{" "}
                        {Number(price.value.max).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "brl"
                    }).slice(0, -3)}
                        <i className="fas fa-caret-down" />
                      </div>
                      <div className="dropdown-menu !w-fit">
                        <div className="widget-wrapper pb20 mb0 pl20 pr20 w-full">
                          <div className="range-wrapper at-home10 w-[20rem] md:w-[30rem]">
                            <InputRange formatLabel={() => ``} maxValue={maxValue} minValue={0} value={price.value} step={1000000} onChange={value => handleOnChange(value)}
                        //@ts-ignore
                        id="slider" />
                            <div className="d-flex align-items-center">
                              <input id="slider-range-value1" value={price.value.min.toLocaleString()} onChange={e => {
                            const min = Number(e.target.value);
                            const max = price.value.max;
                            handleOnChange({
                              min,
                              max
                            });
                          }} />
                              <i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon" />
                              <input id="slider-range-value2" value={price.value.max < maxValue ? price.value.max.toLocaleString() : `${maxValue.toLocaleString()}+`} onChange={e => {
                            const v = e.target.value.replaceAll(",", "").replaceAll(".", "");
                            const value = Number(v);
                            const min = price.value.min;
                            const max = value <= maxValue ? value : maxValue;
                            handleOnChange({
                              min,
                              max
                            });
                          }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End col-md-4 */}

                <div className="col-md-6 col-lg-4 col-xl-3" style={{
              width: 250
            }}>
                  <div className="d-flex align-items-center justify-content-start justify-content-md-center mt-3 mt-md-0">
                    {/* <button
                      className="advance-search-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#advanceSeachModal"
                     >
                      <span className="flaticon-settings" />{" "}
                      {t("buttons.advanced")}
                     </button> */}
                    <button className={`flex items-center justify-center advance-search-icon ud-btn btn-thm ms-4 bg-[${colors.primary}] hover:bg-[${colors.secondary}]`} type="button" onClick={() => {
                  let path = `/fast?${query.toString()}`;
                  router.push(path);
                }}>
                      Buscar
                      <span className="flaticon-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
      </div>
    </div>;
};
export default FilterContent;