"use client";

import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { TbHeart, TbHeartFilled } from "react-icons/tb";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import { useSession } from "next-auth/react";
import { toast } from "sonner";
import api from "@/services/api.service";
import { useGlobalContext } from "@/contexts/global.context";
import { analytics } from "@/lib/utils";
import classes from "./index.module.css";
import { LikeType } from "@mbras-emprendimentos/types";
import * as S from "./styles";
import { useRouter } from "next/navigation";
import { cn } from "@/lib/utils";
export default function ImmobileCard({
  l = {},
  colstyle = "",
  defaultStyle = false,
  animate = true
}: {
  l: any;
  colstyle?: any;
  defaultStyle?: boolean;
  animate?: boolean;
}) {
  const t = useTranslations("immobileCard");
  const session = useSession();
  const {
    likes,
    getData
  } = useGlobalContext();
  const router = useRouter();
  const [listing] = useState({
    id: l.ref,
    type: l.property_type,
    image: l.photos && l?.photos[0],
    title: l.title,
    city: l.city,
    neighborhood: l.neighborhood,
    location: `${l.neighborhood}`,
    suites: l.suites,
    bed: l.bedrooms,
    bath: l.bathrooms,
    sqft: l.usable_area,
    rentPrice: l.is_for_rent && l.rent_value,
    salePrice: l.is_for_sale && l.sale_value,
    forRent: !!l.is_for_rent,
    forSale: !!l.is_for_sale,
    propertyType: l.property_type,
    yearBuilding: 2018,
    featured: l.featured,
    lat: 40.7279707552121,
    long: -74.07152705896405,
    features: l.detunidades
  });
  const [liked, setLiked] = useState<boolean>(false);
  function handleLike(e) {
    e.stopPropagation();
    toast.promise(async () => {
      try {
        if (!session.data) {
          router.push("/login");

          // toast.error("Erro", {
          //   description: "Você precisa estar logado para salvar um imóvel",
          // });

          throw new Error("Você precisa estar logado para salvar um imóvel");
        }
        analytics.track("Immobile Liked", {
          property_ref: listing.id
        });
        const result = await api("/customer/like", {
          method: "POST",
          body: JSON.stringify({
            property_ref: listing.id
          }),
          headers: {
            Authorization: session.data?.user?.access_token || ""
          }
        });
        const {
          status
        } = await result.json();
        setLiked(status);
        await getData();
      } catch (err) {
        toast.error("Erro", {
          description: err.message
        });
      }
    }, {
      loading: "Salvando...",
      success: liked ? "Removido!" : "Salvo!",
      error: "Não foi possivel salvar o imóvel"
    });
  }
  useEffect(() => {
    if (likes.length > 0) {
      setLiked(likes.some((like: LikeType) => like.immobile_id === listing.id));
    }
  }, [likes]);
  return <motion.div initial={{
    scale: animate ? 0.75 : 1,
    opacity: animate ? 0 : 1
  }} animate={{
    scale: 1,
    opacity: 1
  }} exit={{
    scale: animate ? 0.75 : 1,
    opacity: animate ? 0 : 1
  }} transition={{
    type: "spring",
    mass: 0.1
  }} layout={animate} className={cn(defaultStyle ? `${colstyle ? "col-sm-12 col-lg-6" : "col-sm-6 col-lg-4"}` : "", "cursor-pointer")} key={listing.id} onClick={() => {
    analytics.track("Immobile Viewed", {
      property_ref: listing.id
    });
    router.push(`/imovel/${listing.id}`);
  }} data-sentry-element="unknown" data-sentry-component="ImmobileCard" data-sentry-source-file="index.tsx">
      <S.Card className={colstyle ? "listing-style1 listCustom listing-type" : "listing-style1"} style={{
      position: "relative"
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        {/* <p
          style={{
            marginBottom: 0,
            fontWeight: 600,
            position: "absolute",
            top: "1rem",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
            background: "#fff",
            padding: "0.5rem",
            borderRadius: "0.5rem",
            boxShadow: "0 0 0.25rem rgba(0,0,0,0.1)",
          }}
         >
          {listing.id}
         </p> */}
        <span onClick={handleLike} style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 0,
        fontWeight: 600,
        position: "absolute",
        top: "1rem",
        right: "1rem",
        // transform: "translateX(-50%)",
        zIndex: 1,
        background: "#fff",
        padding: "0.5rem",
        borderRadius: "0.5rem",
        boxShadow: "0 0 0.25rem rgba(0,0,0,0.1)"
      }}>
          {liked ? <TbHeartFilled size={18} color="#E4556C" /> : <TbHeart size={18} />}
        </span>
        <div className="list-thumb">
          <Image width={382} height={248} className="w-100 cover" style={{
          height: "230px"
        }} src={listing.image?.src?.imagekit || ""}
        // onError={(e) => {
        //   e.currentTarget.src = listing.image?.src?.imagekit;
        // }}
        alt="listings" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          {/* <div className="sale-sticker-wrap">
           {!listing.forRent && (
           <div className="list-tag fz12">
            <span className="flaticon-electricity me-2" />
            DESTAQUE
           </div>
           )}
           </div> */}
        </div>
        <div className="list-content" style={{
        minHeight: 250,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}>
          {listing.title ? <p className="list-title h-14 line-clamp-2">{listing.title}</p> : <p className="list-title">(Sem título)</p>}
          <p className="list-text">{listing.location}</p>
          <div className="list-meta d-flex align-items-center">
            {listing.suites && <a href="#">
                <span className="flaticon-bed" /> {listing.suites} {t("suite")}
                {listing.suites > 1 ? "s" : ""}
              </a>}
            {listing.bath && <a href="#">
                <span className="flaticon-shower" /> {listing.bath}{" "}
                {t("bathroom")}
                {listing.bath > 1 ? "s" : ""}
              </a>}
            {listing.sqft && <a href="#">
                <span className="flaticon-expand" /> {listing.sqft} m²
              </a>}
          </div>
          <div className="list-meta2 d-flex justify-content-between align-items-center">
            <div className="flex flex-col gap-1 justify-center">
              <div className="list-price">
                {listing.salePrice && Number(listing.salePrice) !== 1 ? Number(listing.salePrice).toLocaleString("pt-br", {
                style: "currency",
                currency: "brl"
              }).slice(0, -3) : listing.salePrice === 1 ? "Sob consulta" : null}
              </div>
              <div className="list-price">
                {listing.rentPrice && Number(listing.rentPrice) !== 1 ? Number(listing.rentPrice).toLocaleString("pt-br", {
                style: "currency",
                currency: "brl"
              }).slice(0, -3) : listing.rentPrice === 1 ? "Sob consulta" : null}{" "}
                {listing.rentPrice ? Number(listing.rentPrice) !== 1 && <span>/ mês</span> : null}
              </div>
            </div>
            <span className="for-what d-none" style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            height: "1.5rem",
            paddingTop: "1rem"
          }}>
              {listing.forSale ? <p className={classes.card}>
                  {listing.forSale ? t("sale") : ""}
                </p> : <></>}
              {listing.forRent ? <p className={classes.card}>
                  {listing.forRent ? t("rent") : ""}
                </p> : <></>}
            </span>
            <div className="icons d-flex align-items-center flex items-center">
              {/* <a href="#">
                <span className="flaticon-fullscreen" />
               </a>
               <a href="#">
                <span className="flaticon-new-tab" />
               </a> */}
              <p className="mb-[-0.1rem] font-bold">{listing.id}</p>
            </div>
          </div>
        </div>
      </S.Card>
    </motion.div>;
}